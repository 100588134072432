import styled from 'styled-components';
import { Row } from 'antd';

interface IChatMessagesContainerStyledProps {}

const PartnerChatMessageStyled = styled(Row)<IChatMessagesContainerStyledProps>`
  &&& {
    .user-chat-message {
    }

    .assistant-chat-message {
      .chat-avatar {
        width: 20px;
        height: 20px;
      }
    }

    .user-chat-message,
    .assistant-chat-message {
      .avatar-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
    }

    @media (max-width: 768px) {
    }
  }
`;

export default PartnerChatMessageStyled;
