import React from 'react';
import { Row, Col } from 'antd';
import MainImage from '@/components/global/MainImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageContainer from '@/components/chat/MessageContainer';
import { faCircleUser } from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import SmallParagraph from '@/components/global/typography/paragraph/SmallParagraph';
import PartnerChatMessageStyled from '@/components/chat/DemoChat/DemoChatMessage/style';

export interface UiMessage {
  uid: string;
  sender: 'You' | 'Bot' | string;
  role: 'assistant' | 'system' | 'user';
  text: string;
  model: string;
}

type MessageBlock = { type: 'text'; content: string } | CodeMessageBlock;
type CodeMessageBlock = {
  type: 'code';
  content: string;
  code: string;
  language: string;
};

const parseAndHighlightCodeBlocks = (text: string): MessageBlock[] => {
  const codeBlockRegex = /`{3,}(\w+)?\n([\s\S]*?)(`{3,}|$)/g;
  const result: MessageBlock[] = [];

  let lastIndex = 0;
  let match;

  while ((match = codeBlockRegex.exec(text)) !== null) {
    result.push({ type: 'text', content: text.slice(lastIndex, match.index) });
    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    result.push({ type: 'text', content: text.slice(lastIndex) });
  }

  return result;
};

function ChatMessageCodeBlock({ codeBlock }: { codeBlock: CodeMessageBlock }) {
  return (
    <Row>
      <Col dangerouslySetInnerHTML={{ __html: codeBlock.content }}></Col>
    </Row>
  );
}

export function DemoChatMessage(props: { uiMessage: UiMessage }) {
  const message = props.uiMessage;
  let collapsedText = message.text;

  return (
    <PartnerChatMessageStyled>
      {message.role === 'user' && (
        <Col
          xs={24}
          md={21}
          className={'user-chat-message text-right ml-auto mr-4'}
        >
          <MessageContainer bgColor={'#FFFFFF'} className={'text-left'}>
            <div className={'avatar-wrapper'}>
              <FontAwesomeIcon
                style={{ width: 20, height: 20 }}
                color={'#E95D53'}
                icon={faCircleUser}
              />
              <SmallParagraph className={'font-semibold ml-2'}>
                You
              </SmallParagraph>
            </div>
            {parseAndHighlightCodeBlocks(collapsedText).map((part, index) =>
              part.type === 'code' ? (
                <ChatMessageCodeBlock key={index} codeBlock={part} />
              ) : (
                <MainParagraph
                  key={index}
                  className={'whitespace-break-spaces'}
                >
                  {part.content}
                </MainParagraph>
              ),
            )}
          </MessageContainer>
        </Col>
      )}
      {message.role === 'assistant' && (
        <Col xs={24} md={21} className={'assistant-chat-message'}>
          <MessageContainer bgColor={'#FFFFFF'}>
            <div className={'avatar-wrapper'}>
              <div className={'chat-avatar'}>
                <MainImage src={'/chat/Avatar.svg'} alt={'AI Doctor'} />
              </div>

              <SmallParagraph className={'font-semibold ml-2'}>
                AI Doctor
              </SmallParagraph>
            </div>
            {parseAndHighlightCodeBlocks(collapsedText).map((part, index) =>
              part.type === 'code' ? (
                <ChatMessageCodeBlock key={index} codeBlock={part} />
              ) : (
                <MainParagraph
                  key={index}
                  className={'whitespace-break-spaces'}
                >
                  {part.content}
                </MainParagraph>
              ),
            )}
          </MessageContainer>
        </Col>
      )}
    </PartnerChatMessageStyled>
  );
}
