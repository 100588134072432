import LimitRichesStateStyled from '@/components/pages/home/ChatSection/DemoChat/LimitRichesState/style';
import QuestionSection from '@/components/pages/home/ChatSection/DemoChat/LimitRichesState/QuestionSection';

const LimitRichesState = () => {
  const questions = [
    'I have more questions',
    'What should I do next?',
    'Can you analyze my test results?',
    'I need advice from a top doctor',
  ];

  return (
    <LimitRichesStateStyled>
      <QuestionSection questions={questions} />
    </LimitRichesStateStyled>
  );
};

export default LimitRichesState;
