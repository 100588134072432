import styled from 'styled-components';

const DemoChatSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 916px;
  width: 100%;
  margin: 0 auto;
  .composer-container {
    margin-bottom: 24px;
    background-color: #f5f5f5;
    border: 1px solid #eaeaec;
    border-radius: 20px;
    padding: 24px 24px 0;
    width: 100%;
    .main-img-wrapper {
      max-width: 212px;
      margin: 0 auto 24px;
    }
    .chat_text_area {
      box-shadow: none !important;
    }
  }

  .please-note-section {
    margin: 24px 0;
    .note-txt {
      text-align: center;
      padding-bottom: 24px;
    }
    .img-wrapper {
      display: flex;
      justify-content: center;
      .img-item {
        height: 72px;
        &.hippa {
          max-width: 108px;
          width: 100%;
          margin-right: 40px;
        }
        &.gdpr {
          max-width: 75px;
          width: 100%;
        }
      }
    }
    &.form-view {
      padding: 0 160px;
    }
  }

  @media (max-width: 768px) {
    .composer-container {
      padding: 16px 16px 0;
      border-radius: 8px;
    }
    .please-note-section {
      padding: 0;
      &.form-view {
        padding: 0;
      }
    }
  }
`;

export default DemoChatSectionStyled;
