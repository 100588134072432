import { Button } from 'antd';
import styled from 'styled-components';

interface PrimaryButtonProps {
  children: React.ReactNode;
}

const DocusGreenLinkButton = styled(Button)<PrimaryButtonProps>`
  &&& {
    background-color: transparent;
    border: 0px;
    color: #1a847c;
    font-size: 14px;
    font-style: normal;
    padding: 0px;
    height: auto;
    font-weight: 400;
    line-height: 22px;
    display: inline-block;

    &:hover,
    &:focus,
    &:not(:disabled):hover {
      color: #26b3aa;
    }

    span {
      text-decoration: underline;
    }
  }
`;

export default DocusGreenLinkButton;
