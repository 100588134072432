import DarkHeading4 from '@/components/global/typography/heading/Heading4/DarkHeading4';
import PrimaryGreenLink from '@/components/global/typography/links/PrimaryGreenLink';
import { Col, Row } from 'antd';
import QuestionButton from '@/components/pages/symptom-checker/SymptomChecker/HealthInsights/QuestionButton';
import React from 'react';
import { SIGN_UP_URL } from '@/utils/config';

interface QuestionSectionProps {
  questions: string[];
}

const QuestionSection = ({ questions }: QuestionSectionProps) => {
  return (
    <div className={'question-section'}>
      <div className={'text-center'}>
        <DarkHeading4 className={'mb-6 mt-0'}>
          <PrimaryGreenLink className={'mr-2'} href={SIGN_UP_URL}>
            Sign-up
          </PrimaryGreenLink>
          to continue with AI Doctor
        </DarkHeading4>
      </div>

      <Row gutter={[16, 16]} className={'questions-wrapper'}>
        {questions.map((question) => {
          return (
            <Col key={question} xs={24} md={12}>
              <QuestionButton text={question} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default QuestionSection;
