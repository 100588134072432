import styled from "styled-components";
import React, {ReactNode} from "react";
import {ParagraphProps} from "antd/es/typography/Paragraph";


const SemiboldParagraphStyled = styled.p`
  &&& {
    color: #272A3D !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;

    @media(max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

interface SemiboldParagraphProps extends ParagraphProps {
    children: ReactNode;
}

const SemiboldParagraph: React.FC<SemiboldParagraphProps> = ({ children, ...props }) => {
    return (
        <SemiboldParagraphStyled {...props}>
            {children}
        </SemiboldParagraphStyled>
    );
};


export default  SemiboldParagraph
