'use client';

import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { ParagraphProps } from 'antd/es/typography/Paragraph';

const SmallRedTextStyled = styled.span`
  color: #e95d53 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0 !important;
`;

interface SmallLightGreyParagraphProps extends ParagraphProps {
  children: ReactNode;
}

const SmallRedText: React.FC<SmallLightGreyParagraphProps> = ({
  children,
  ...props
}) => {
  return <SmallRedTextStyled {...props}>{children}</SmallRedTextStyled>;
};

export default SmallRedText;
