import styled from 'styled-components';

const LimitRichesStateStyled = styled.div`
  .question-section {
    padding-top: 16px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    .question-section {
      padding-top: 16px;
    }
  }
`;

export default LimitRichesStateStyled;
