import styled from 'styled-components';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlaneTop } from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import { Analytics } from '@/libs/GoogleAnalytics';
import MainLink from '@/components/global/typography/links/MainLink';
import { SIGN_UP_URL } from '@/utils/config';

interface QuestionButtonProps {
  text: string;
}

const StyledOutlinedButton = styled(MainLink)`
  &&& {
    background-color: transparent;
    border: 1px solid #1a847c;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 12px;
    transition: 0.2s;

    .text {
      color: #1a847c !important;
      font-weight: 600;
    }

    &:hover,
    &:focus,
    &:not(:disabled):hover {
      text-decoration: none;
      background: #e9f3f2;
      border-color: #1a847c;
      color: #1a847c;
    }
  }
`;

const QuestionButton = ({ text }: QuestionButtonProps) => {
  return (
    <StyledOutlinedButton
      onClick={() => Analytics.sendSymptomCheckerRedirectToDashboardEventToGA()}
      href={SIGN_UP_URL}
    >
      <MainParagraph className={'text mb-0'}>{text}</MainParagraph>
      <FontAwesomeIcon
        color={'#1A847C'}
        icon={faPaperPlaneTop}
        width={'14px'}
        height={'14px'}
      />
    </StyledOutlinedButton>
  );
};

export default QuestionButton;
