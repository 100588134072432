import styled from 'styled-components';

const DemoComposerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .conversation-container-wrapper {
    //border-bottom: 1px solid #eaeaec;
    background-color: #f5f5f5;

    .scroll-container {
      overflow-x: hidden;
      overflow-y: auto;
      & > div {
        overflow-x: hidden !important;
        &:last-child {
          width: 3px !important;
          & > div {
            background-color: #1a847c !important;
          }
        }
      }
    }
  }

  .demo_chat_text_area_wrapper {
    .demo_chat_text_area {
      min-height: 56px;
      font-size: 18px;
      line-height: 28px;
      padding: 12px 60px 12px 12px;
      box-shadow: none !important;
      max-height: 120px;
      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #1a847c;
      }
      &:not(:placeholder-shown) {
        //line-height: 22px;
        padding-bottom: 12px;
      }
      &:hover,
      &:focus {
        border-color: #1a847c;
      }
    }

    .send-button-wrapper {
      position: absolute;
      bottom: 12px;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .send-button {
        max-width: 32px;
        width: 100%;
        height: 32px !important;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: 16px;
          height: 14px;
          path {
            width: 16px;
            height: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .demo_chat_text_area_wrapper {
      .demo_chat_text_area {
        font-size: 16px;
        line-height: 24px;
      }

      .send-button-wrapper {
        position: absolute;
        bottom: 12px;
        right: 16px;

        .send-button {
          height: 32px !important;

          .icon {
            path {
              width: 16px;
              height: 14px;
            }
          }
        }
      }
    }
  }
`;

export default DemoComposerStyled;
