'use client';
import { ChangeEvent, useState } from 'react';
import { Col, Input, Row } from 'antd';
import { PulseLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlaneTop } from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import PrimaryGreenButton from '@/components/global/buttons/PrimaryGreenButton';

const { TextArea } = Input;

export function Composer({
  isDeveloper,
  disableSend,
  sendMessage,
}: {
  isDeveloper: boolean;
  disableSend: boolean;
  sendMessage: (text: string) => void;
}) {
  const [composeText, setComposeText] = useState('');

  const handleSendClicked = () => {
    const text = (composeText || '').trim();
    if (text.length) {
      setComposeText('');
      sendMessage(text);
    }
  };

  const textPlaceholder: string = 'Type your question here...';
  const maxLength: number = 2000;

  const handleChatTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const countElement = document.querySelector(
      '.ant-input-data-count',
    ) as HTMLElement;
    const chatTextArea = document.querySelector(
      '.partners_chat_text_area',
    ) as HTMLElement;

    const value: string = e.target.value;
    setComposeText(e.target.value);

    if (value.length >= maxLength) {
      if (countElement) {
        countElement.style.color = '#DC3545';
      }
      if (chatTextArea) {
        chatTextArea.style.borderColor = '#DC3545';
      }
    } else {
      if (countElement) {
        countElement.style.color = '#7A7C88';
      }
      if (chatTextArea) {
        chatTextArea.style.borderColor = '#EEEEEE';
      }
    }
  };

  return (
    <Row className={'demo_chat_text_area_wrapper'}>
      <Col xs={24} className={'relative'}>
        <TextArea
          placeholder={textPlaceholder}
          className={'demo_chat_text_area'}
          maxLength={maxLength}
          value={composeText}
          onChange={handleChatTextAreaChange}
          autoSize
        />
        <div
          onClick={handleSendClicked}
          className={'absolute send-button-wrapper'}
        >
          {!disableSend && (
            <PrimaryGreenButton className={'send-button'}>
              <FontAwesomeIcon className={'icon'} icon={faPaperPlaneTop} />
            </PrimaryGreenButton>
          )}
          {disableSend && (
            <PulseLoader color={'#1A847C'} size={8} speedMultiplier={0.5} />
          )}
        </div>
      </Col>
    </Row>
  );
}
