import styled from "styled-components";
import React, {ReactNode} from "react";
import {TextProps} from "antd/es/typography/Text";

const ErrorRedTextStyled = styled.span`
  &&& {
    color: #F73140;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: inline-block;
  }
`;

interface ErrorRedTextProps extends TextProps {
    children: ReactNode;
}

const ErrorRedText: React.FC<ErrorRedTextProps> = ({ children, ...props }) => {
    return (
        <ErrorRedTextStyled {...props}>
            {children}
        </ErrorRedTextStyled>
    );
};


export default  ErrorRedText
