import styled from 'styled-components';
import React from 'react';

interface MessageContainerProps {
  children: React.ReactNode;
  className?: string;
  bgColor: string;
  borderColor?: string;
}

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  $borderColor?: string;
}

const Container = styled.div<ContainerProps>`
  &&& {
    width: 100%;
    border: 1px solid
      ${(props) => props.$borderColor || 'rgba(39, 42, 61, 0.1)'};
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 24px;
  }
`;

const MessageContainer = ({
  children,
  className,
  bgColor,
  borderColor,
}: MessageContainerProps) => {
  return (
    <Container
      style={{ backgroundColor: bgColor }}
      $borderColor={borderColor}
      className={className}
    >
      {children}
    </Container>
  );
};

export default MessageContainer;
