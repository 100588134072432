import { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Col, message, Row } from 'antd';
import DemoComposerStyled from './style';
import { isMobile } from 'react-device-detect';
import Scrollbars from 'react-custom-scrollbars-2';
import { Analytics } from '@/libs/GoogleAnalytics';
import MainImage from '@/components/global/MainImage';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { UiMessage } from '@/components/chat/ChatMessage';
import ErrorRedText from '@/components/global/typography/text/ErrorRedText';
import SmallRedText from '@/components/global/typography/text/SmallRedText';
import { Composer } from '@/components/chat/DemoChat/DemoComposer/Composer';
import { DemoChatMessage } from '@/components/chat/DemoChat/DemoChatMessage';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DocusGreenLinkButton from '@/components/global/buttons/DocusGreenLinkButton';
import SemiboldParagraph from '@/components/global/typography/paragraph/SemiboldParagraph';
import LimitRichesState from '@/components/pages/home/ChatSection/DemoChat/LimitRichesState';
import SmallLightGreyParagraph from '@/components/global/typography/paragraph/SmallLightGreyParagraph';

const MessageDefaults: {
  [key in UiMessage['role']]: Pick<UiMessage, 'role' | 'sender'>;
} = {
  system: {
    role: 'system',
    sender: 'Bot',
  },
  user: {
    role: 'user',
    sender: 'User',
  },
  assistant: {
    role: 'assistant',
    sender: 'Assistant',
  },
};

interface IPartnersComposerProps {}

const DemoComposer = ({}: IPartnersComposerProps) => {
  const [messages, setMessages] = useState<UiMessage[]>([]);
  const [publicIdentifier, setPublicIdentifier] = useState(
    Cookies.get('safeIdentity') || '',
  );
  const [limit, setLimit] = useState<number>(0);
  const [disableCompose, setDisableCompose] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [rateLimitError, setRateLimitError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [newConversation, setNewConversation] = useState<boolean>(
    conversationId == null,
  );
  let clientHeight = 600;

  const scrollbarRef = useRef<Scrollbars>(null);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const conversationContainerWrapperRef = useRef<HTMLDivElement>(null);

  const listEmpty = !messages.length;

  const createUiMessage = (
    role: UiMessage['role'],
    text: string,
  ): UiMessage => ({
    uid: Math.random().toString(36).substring(2, 15),
    text: text,
    model: '',
    ...MessageDefaults[role],
  });

  const fetchRateLimit = async () => {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const identity = await fp.get();
    const fingerprintIdentifier = identity.visitorId;

    setPublicIdentifier(fingerprintIdentifier);

    try {
      const rateLimiterResponse = await fetch(
        process.env.APP_URL + '/api/chat/initial-rate-limit',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Safe-Identifier': fingerprintIdentifier || '',
          },
        },
      );
      const limitCheck = await rateLimiterResponse.json();
      setTimeout(() => {
        setLimit(limitCheck.limit.message);
      }, 200);
    } catch (error) {
      setLimit(0);
    }
  };

  useEffect(() => {
    fetchRateLimit();
  }, []);

  const getBotMessageStreaming = async (lastMessage: string) => {
    setTimeoutError(false);
    setRateLimitError(false);

    let updatedConversationId = conversationId;

    if (conversationId === null) {
      updatedConversationId = uuidv4();
      setConversationId(updatedConversationId);
    }

    const response = await fetch('/api/chat/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Safe-Identifier': publicIdentifier,
      },
      body: JSON.stringify({
        message: lastMessage,
        conversationId: updatedConversationId,
        newConversation: newConversation,
      }),
    });

    if (response.status === 403) {
      messageApi.open({
        type: 'error',
        content: 'Something went wrong. Please refresh page and try again',
        className: 'custom-class',
        style: {
          marginTop: '20vh',
        },
      });
      return false;
    }

    if (response.status === 408) {
      setTimeoutError(true);
      return false;
    }

    if (response.body) {
      setNewConversation(false);
      setLimit(limit - 1);

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      const newBotMessage: UiMessage = createUiMessage('assistant', '');
      let messagesToSave = messages;

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          break;
        }
        const messageText = decoder.decode(value);
        newBotMessage.text += messageText;

        if (parseInt(messageText) === 429) {
          setRateLimitError(true);
          return false;
        }

        if (!newBotMessage.model && newBotMessage.text.startsWith('{')) {
          const endOfJson = newBotMessage.text.indexOf('}');
          if (endOfJson > 0) {
            const json = newBotMessage.text.substring(0, endOfJson + 1);
            try {
              const parsed = JSON.parse(json);

              newBotMessage.model = parsed.model;
              newBotMessage.text = newBotMessage.text.substring(endOfJson + 1);
            } catch (e) {
              console.log('Error parsing JSON: ' + e);
            }
          }
        }

        setMessages((list) => {
          const message = list.find(
            (message) => message.uid === newBotMessage.uid,
          );
          return !message ? [...list, newBotMessage] : [...list];
        });
      }

      messagesToSave.push(newBotMessage);
    }
  };

  const handleComposerSendMessage: (text: string) => void = (text) => {
    const conversation = [...messages];
    conversation.push(createUiMessage('user', text));
    setMessages(conversation);
    setDisableCompose(true);
    getBotMessageStreaming(text).then(() => setDisableCompose(false));
    Analytics.sendPartnersDemoChatSendMessageButtonClickEventToGA();
  };

  useEffect(() => {
    const scrollbar = scrollbarRef.current;
    if (scrollbar) {
      clientHeight = scrollbar.getClientHeight() || 600;
      scrollbar.scrollToBottom();
    }
  }, [messages]);

  const scrollContainerHeight = useMemo(() => {
    const limitRiches = limit <= 0;

    let height = 0;

    if (isMobile) {
      //mobile

      if (!listEmpty) {
        height = limitRiches ? 265 : 500;
      } else {
        height = limitRiches ? 265 : 500;
      }
    } else {
      //desktop

      if (!listEmpty) {
        height = limitRiches ? 380 : 400;
      } else {
        height = limitRiches ? 380 : 380;
      }
    }
    return height;
  }, [listEmpty, limit, isMobile]);

  useEffect(() => {
    const scrollbar = scrollbarRef.current;
    const conversationContainer = conversationContainerWrapperRef.current;
    const messagesContainer = messagesContainerRef.current;

    if (!scrollbar || !conversationContainer || !messagesContainer) return;

    if (
      messages.length > 0 &&
      messagesContainer.clientHeight > scrollContainerHeight
    ) {
      conversationContainer.style.borderBottom = '1px solid #eaeaec';
    }
  }, [scrollbarRef.current, conversationContainerWrapperRef.current, messages]);

  return (
    <DemoComposerStyled
      className={`${!listEmpty && limit <= 0 ? 'justify-start' : ''}`}
    >
      <div
        ref={conversationContainerWrapperRef}
        className={`conversation-container-wrapper ${listEmpty ? 'border-0' : ''}`}
      >
        <Scrollbars
          className={'scroll-container'}
          ref={scrollbarRef}
          style={{
            height: scrollContainerHeight,
          }}
          universal={true}
        >
          <Row>
            <Col xs={24}>
              {listEmpty ? (
                <Col xs={24} className={'pt-7  '}>
                  <Row className={'flex justify-center items-center'}>
                    <Col xs={24} className={' text-center'}>
                      <div className={'main-img-wrapper'}>
                        <MainImage
                          src={'/chat/assistant.png'}
                          alt={'Hi! I’m your AI Doctor'}
                        />
                      </div>
                      <MainParagraph className={'text-center'}>
                        I'm here to support with any health-related questions
                        you may have.
                      </MainParagraph>
                      <SemiboldParagraph className={'text-center'}>
                        How can I help you?
                      </SemiboldParagraph>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <div ref={messagesContainerRef} className={'mb-4'}>
                  {messages.map((message) => (
                    <DemoChatMessage
                      key={'msg-' + message.uid}
                      uiMessage={message}
                    />
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Scrollbars>
      </div>

      {/*What should I do next ?*/}

      {listEmpty && limit <= 0 && <LimitRichesState />}
      {!listEmpty && limit <= 0 && <LimitRichesState />}

      {limit > 0 && (
        <Row>
          {rateLimitError && (
            <Col xs={24} className={'flex flex-row justify-center mb-4'}>
              <ErrorRedText className={'mr-1'}>
                Something went wrong, please try again.
              </ErrorRedText>
              <DocusGreenLinkButton>Retry!</DocusGreenLinkButton>
            </Col>
          )}

          {timeoutError && (
            <Col xs={24} className={'flex flex-row justify-center mb-4'}>
              <ErrorRedText className={'mr-1'}>
                Your request took too long to process. Please try again.
              </ErrorRedText>
              <DocusGreenLinkButton>Retry!</DocusGreenLinkButton>
            </Col>
          )}

          <Col xs={24} className={'mb-4'}>
            <Composer
              isDeveloper={false}
              disableSend={disableCompose}
              sendMessage={handleComposerSendMessage}
            />
            <SmallLightGreyParagraph className={'flex justify-center mt-3'}>
              You have
              <SmallRedText className={'px-1'}>{limit} message(s) </SmallRedText>
              available
            </SmallLightGreyParagraph>
          </Col>
        </Row>
      )}
    </DemoComposerStyled>
  );
};

export default DemoComposer;
